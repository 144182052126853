@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.project {
  width: em(900);
  max-width: calc(100% - em(20));
  margin: 0 auto;
  padding: em(60) em(10);
  .project-wrapper {
    .project-image {
      img {
        width: 100%;
      }
    }

    .project-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: em(20) 0;
      h2 {
        font-size: em(40);
        background: linear-gradient(120deg, $main-color 0%, $main-color 100%);
        background-repeat: no-repeat;
        background-size: 100% 40%;
        background-position: 0 90%;
      }
    }

    .first {
      display: flex;
      flex-direction: column;

      @include mq($tablet) {
        flex-direction: row;
      }
      .spec {
        flex: 0.5;
        padding-bottom: em(20);
        p {
          line-height: em(30);
        }

        h3 {
          text-decoration: underline;
        }

        .links {
          display: flex;

          @include mqs($desktop) {
            justify-content: center;
            align-items: center;
          }

          a {
            display: block;
            text-align: center;
            width: em(100);
            border: 2px solid $text-color;
            padding: em(5);
            background-color: $background-color;
            color: $text-color;
            margin: em(10) em(10) 0 0;
            &:hover {
              background-color: $text-color;
              color: $background-color;
            }
          }
        }
      }

      .overview {
        flex: 0.5;
        h3 {
          text-decoration: underline;
        }
      }
    }

    .cta {
      margin-top: em(50);
      display: flex;
      justify-content: center;
      align-items: center;
      .cta-button {
        display: block;
        text-align: center;
        width: em(140);
        padding: em(10) 0;
        border: 2px solid $text-color;
        cursor: pointer;
        background-color: $background-color;
        color: $text-color;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:before {
          font-family: "Font Awesome 5 Free";
          color: $background-color;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          font-size: em(30);
        }

        &:hover {
          color: $background-color;
          background-color: $text-color;
        }
      }
    }
  }
}
