.page-works {
  width: em(900);
  max-width: calc(100% - em(20));
  margin: 0 auto;
  padding: em(60) em(10);

  .page-works-wrapper {
    padding-bottom: em(20);
    .ul {
      list-style: none;
      padding-left: 0;
      display: flex;

      .first-li {
        margin-right: em(6);
        color: $text-color;
      }
      .second-li {
        color: $main-color;
        svg {
          margin-right: em(6);
          color: $text-color;
        }
      }
    }
  }
 }

      .intro-btn-container {
        display: flex;
        justify-content: start;
        flex-direction: column;
        margin-top: em(20);

        @include mq($desktop) {
          margin-top: em(20);
          justify-content: start;
          margin-left: em(50);
        }

        .intro-text-heading {
          @include mq($tablet) {
            margin-bottom: em(20);
          }
          h2 {
            font-size: $font-size-h2;
          }
        }
        .intro-btn-wrapper {
          display: flex;
          justify-content: center;
          a {
            display: block;
            text-align: center;
            width: em(140);
            padding: em(10) 0;
            background-color: $background-color;
            color: $text-color;
            border: 2px solid $text-color;
            cursor: pointer;
            transition: all 0.4s ease;
            position: relative;

            &:first-child {
              margin-right: em(10);
            }

            &:hover {
              background-color: $text-color;
              color: $background-color;
            }
          }
        }
      }
