//Intro
.intro {
  .intro-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mq($desktop) {
      padding: 0 em(50);
    }
    .heading-wrapper {
      display: grid;
      place-content: center;
      .intro-text {
        padding-top: em(10);
        font-weight: 800;
        font-size: $font-size-h1;

        @include mq($tablet) {
          padding-top: em(10);
        }

        @include mq($desktop) {
          padding-top: em(5);
          padding-left: em(10);
        }

        .highlight {
          background: linear-gradient(120deg, $main-color 0%, $main-color 100%);
          background-repeat: no-repeat;
          background-size: 100% 35%;
          background-position: 0 90%;
        }
      }
      .intro-text-two {
        font-size: $font-size-h2;
        margin-top: em(10);

        @include mq($tablet) {
        }

        @include mq($desktop) {
          padding-left: em(22);
        }

        .typewriter {
          display: inline-block;
          width: em(180);

          .underline {
            background: linear-gradient(
              120deg,
              $main-color 0%,
              $main-color 100%
            );
            background-repeat: no-repeat;
            background-size: 100% 35%;
            background-position: 0 90%;
          }
        }
      }
      .intro-btn-container {
        display: flex;
        justify-content: start;
        flex-direction: column;
        margin-top: em(30);

        @include mq($desktop) {
          margin-top: em(50);
          justify-content: start;
          margin-left: em(50);
        }

        .intro-text-heading {
          @include mq($tablet) {
            margin-bottom: em(20);
          }
          h2 {
            font-size: $font-size-h2;
          }
        }
        .intro-btn-wrapper {
          display: flex;
          justify-content: start;
          a {
            display: block;
            text-align: center;
            width: em(140);
            padding: em(10) 0;
            background-color: $background-color;
            color: $text-color;
            border: 2px solid $text-color;
            cursor: pointer;
            transition: all 0.4s ease;
            position: relative;

            &:first-child {
              margin-right: em(10);
            }

            &:hover {
              background-color: $text-color;
              color: $background-color;
            }
          }
        }
      }
    }
  }
}


//Works
.works {
  .works-title {
    text-align: center;
    padding: em(20) 0;
    font-size: em(25);

    @include mq($desktop) {
      font-size: em(30);
    }

    @include mq($desktopMid) {
      font-size: em(40);
    }
  }

  .works-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .work-item {
      width: em(330);

      @include mq($tablet) {
        width: em(1000);
      }

      &:not(:last-child) {
        margin-bottom: em(50);
      }

      .work-item-wrapper {
        background-color: $div-box;
        border-radius: em(10);
        margin: 0 em(10);
        padding: em(30) em(20);
        display: flex;
        height: em(500);
        flex-direction: column;
        box-shadow: $shadow-color 0px 5px 15px;

        @include mq($tablet) {
          padding: em(30) em(50);
        }

        @include mq($desktop) {
          flex-direction: row;
          margin: 0 em(40);
          height: em(400);
        }

        .work {
          flex: 0.7;
          display: flex;
          justify-content: center;
          align-items: center;

          @include mq($desktopMid) {
            flex: 0.6;
          }

          .work-image-container {
            width: em(200);
            height: em(200);
            position: relative;

            @include mq($tablet) {
              width: em(300);
            }

            @include mq($desktopMid) {
              width: em(400);
            }

            .img1 {
              width: em(250);
              position: absolute;

              @include mq($tablet) {
                width: em(350);
              }

              @include mq($desktopMid) {
                left: 0;
                top: em(-50);
              }
            }

            img:nth-child(2) {
              position: absolute;
              width: em(50);
              bottom: em(30);
              left: em(-40);

              @include mq($tablet) {
                width: em(70);
                bottom: em(-50);
              }

              @include mq($desktopMid) {
                width: em(80);
                left: em(-20);
              }
            }

            img:nth-child(3) {
              position: absolute;
              width: em(100);
              left: em(140);
              bottom: em(30);
              background-color: transparent;

              @include mq($tablet) {
                width: em(140);
                left: em(240);
                bottom: em(-50);
              }
            }
          }
        }

        .work-info {
          flex: 0.3;

          @include mq($desktop) {
            padding-left: em(30);
          }

          @include mq($desktopMid) {
            flex: 0.4;
          }

          .work-info-title {
            display: flex;
            justify-content: center;

            @include mq($desktopMid) {
              justify-content: start;
            }

            h3 {
              font-size: em(25);
              margin: em(20) 0;
              font-weight: 900;

              background: linear-gradient(
                120deg,
                $main-color 0%,
                $main-color 100%
              );
              background-repeat: no-repeat;
              background-size: 100% 40%;
              background-position: 0 90%;

              @include mq($tablet) {
                font-size: em(30);
                margin-top: em(25);
              }

              @include mq($desktopMid) {
                font-size: em(40);
                margin: 0 0 em(30) 0;
              }
            }
          }

          p {
            margin-left: em(15);
            text-align: center;

            @include mq($desktopMid) {
              text-align: left;
              margin-left: 0;
            }
          }

          .work-tags {
            font-size: em(12);
            color: $text-color;
            margin-top: em(10);
          }

          .work-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: em(20);

            @include mq($desktopMid) {
              justify-content: start;
            }

            a {
              display: block;
              text-align: center;
              width: em(140);
              padding: em(10) 0;
              background-color: $background-color;
              color: $text-color;
              border: 2px solid $text-color;
              cursor: pointer;
              position: relative;

              &:nth-child(1) {
                margin-right: em(20);
              }

              &:hover {
                background-color: $text-color;
                color: $background-color;
              }
            }
          }
        }
      }
    }
  }
}

