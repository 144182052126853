// Mixins

// Media Queries
@mixin mq($w){
    @media(min-width: em($w)){
        @content;
    }
}

@mixin mqs($w){
    @media(max-width: em($w)){
        @content;
    }
}
