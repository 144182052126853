.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding: 20px;
  }

.contact-wrapper {
    background-color: #f0f0f1;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    text-align: center;
    width: 100%;
  }
  .contact-title {
          h2 {
            font-size: em(25);
    
            @include mq($desktop) {
             font-size: em(30);
            }
    
         @include mq($desktopMid) {
              font-size: em(40);
            }
          }
        }
.contact-info a {
    
        background-color: $background-color;
        color: $text-color;
        font-size: em(20);
        border: em(2) solid $text-color;
        line-height: em(40);
        width: 50%;
        padding: 0.2rem;

        &:hover {
          color: $background-color;
          background-color: $text-color;
        }
  }

.contact-info a:hover {
    text-decoration: none;
  }
.contact-info p {
    font-size: 1em;
    line-height: 1.5;
    padding: 1rem;
  }

  .social {
        margin: em(10) 0;
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: center;

        li {
            margin: 0 em(5);

            a {
                color: $text-color;

                &:hover {
                    color: $white;
                }
                
                svg {
                    font-size: em(40);
                }
            }
        }
    }
   
  
  