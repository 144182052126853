//Variables

//Colors
$black: #000000;
$dark: #121212;
$lightdark: #1e1e1e;
$white: wheat;
$lightwhite: #f0f0f1;
$mainColor: #4a83b7;
$subColor: #ffd364;
$shadeColor: rgba(0, 0, 0, 0.35);
$shadeOppColor: rgba(255, 255, 255, 0.35);
$shadowColorTwo: rgba(0, 0, 0, 0.18);
$shadowOppColorTwo: rgba(255, 255, 255, 0.18);
$shadowColorThree: rgba(0, 0, 0, 0.1);
$shadowOppColorThree: rgba(255, 255, 255, 0.1);
$shadowColorFour: rgba(0, 0, 0, 0.15);
$shadowOppColorFour: rgba(0, 0, 0, 0.15);
$shadowColorFive: rgba(0, 0, 0, 0.65);
$shadowOppColorFive: rgba(255, 255, 255, 0.65);
$background-color: wheat;
$text-color: black;
$div-box: $lightwhite;
$main-color: var(--main-color);
$shadow-color: var(--shadow-color);
$shadow-color-two: var(--shadow-color-two);
$shadow-color-three: var(--shadow-color-three);
$shadow-color-four: var(--shadow-color-four);
$shadow-color-five: var(--shadow-color-five);


// Breakpoints
$navMobile: 405;
$tablet: 540;
$desktop: 820;
$desktopMid: 1000;
$desktopWide: 1300;
$desktopUltra: 2000;

// Fluid Type Scale
$font-size-h1: clamp(35px, 4.88vw + 15.51px, 79px);
$font-size-h2: clamp(1.13rem, 2.5vw + 0.5rem, 2.5rem);
