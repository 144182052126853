.nav-main {
    width: em(450);
    height: 100vh;
    background-color: grey;
    position: fixed;
    top: 0;
    right: em(-450);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    &.active {
        right: 0;
    }

    .main {
        margin: 0;
        padding: 0;
        list-style: none;
        color: $white;
        font-size: 40px;

        li {
            margin: em(10) 0;
            padding-left: em(20);
            transition: all 0.2s ease-in-out;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 70%;
                transform: scaleX(0);
                height: em(2);
                bottom: 0;
                left: em(20);
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
                background-color: $main-color;            
            }

            &:hover {
                &::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }

            &.active {
                &::before {
                    content: "";
                    position: absolute;
                    width: 70%;
                    height: em(2);
                    bottom: 0;
                    left: em(20);
                    background-color: $main-color;
                }
            }

            a {
                color: $white;
                text-decoration: none;

                &:hover {
                    color: #F0F0F1;
                }
            }
        }
    }
}