.footer {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p:last-child {
    text-align: center;
  }

  @include mqs($navMobile) {
    margin-bottom: em(100);
    text-align: center;
  }
}
